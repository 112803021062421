import { ApolloError } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";

type PaginatedResponse<T> = {
  items: T[];
  metadata: {
    page: number;
    pageSize: number;
    total: number;
  };
};

type QueryHook<T, K extends string> = (options: {
  variables: {
    pageInput: { page: number; pageSize: number };
    input: {
      query: string | null;
    };
  };
}) => {
  data?: { [key in K]: PaginatedResponse<T> };
  error?: ApolloError;
  loading: boolean;
};

export const useFetchAllPages = <T, K extends string>(
  queryHook: QueryHook<T, K>,
  responseKey: K,
  options?: {
    pageSize?: number;
    query?: string | null;
  }
) => {
  const { pageSize = 50, query = null } = options ?? {
    pageSize: 50,
  };
  const [allItems, setAllItems] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const { data, loading, error } = queryHook({
    variables: {
      pageInput: { page: currentPage, pageSize },
      input: { query },
    },
  });

  useEffect(() => {
    if (data?.[responseKey]) {
      const { items, metadata } = data[responseKey];
      setAllItems((prev) => [...prev, ...items]);

      const totalPages = Math.ceil(metadata.total / metadata.pageSize);
      if (currentPage < totalPages) {
        setIsLoadingMore(true);
        setCurrentPage((prev) => prev + 1);
      } else {
        setIsLoadingMore(false);
      }
    }
  }, [data]);

  const currentData = data?.[responseKey]?.items ?? [];

  return {
    // Return both current page and accumulated data
    data: allItems,
    currentPageData: currentData,
    loading: loading && currentPage === 1, // Only show loading for first page
    isLoadingMore, // Separate loading state for additional pages
    error,
  };
};
